import React, { useState, useEffect } from 'react';
import classes from './CreatePurchase.module.css';
// import "../../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

import LatestDash from '../LatestDashboard/LatestDash';
import CurrencyInput from 'react-currency-input-field';

const initialState = () => {
  return [{ items: '', unitPrice: '', qty: '', vat: '', discountPercentage: '', discount: '', amount: '' }];
};


export default function CreatePurchase() {
  const [user, setUser] = useState("");
  const [show, setShow] = useState(false);
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [bankName, setBankName] = useState('');
  const [accName, setAccName] = useState('');
  const [accNo, setAccNo] = useState('');
  const [officeAddress, setOfficeAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [debitGl, setDebitGl] = useState('');
  const [glMethod, setGlMethod] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [selectedRep, setSelectedRep] = useState('');
  const [sICode, setSICode] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [accountName, setAccountName] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [debitCode, setDebitCode] = useState('');
  const [debitAmount, setDebitAmount] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState(initialState);
  // const [loading, setLoading] = useState(false);
  const [totalCharge, setTotalCharge] = useState("");
  const [itemList, setItemList] = useState([]);
  const [generalInvoices, setGeneralInvoices] = useState([]);
  const [mod, setMod] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [debitAccount, setDebitAccounts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState('');
  const [totalTax, setTotalTax] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedTax, setSelectedTax] = useState('');
  const [selectedMode, setSelectedMode] = useState('');
  const [customerBalance, setCustomerBalance] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [repName, setRepName] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [currency, setCurrency] = useState([]);
  const [currLoading, setCurrLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);




  const handleCustomerChange = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedCustomer(selectedOption.value);
    }
  };

  const handleSalesChange = (selectedOption) => {
    const selectedSaleRepId = selectedOption.value;
    setSelectedRep(selectedSaleRepId);
  };


  useEffect(() => {
    const mergedData = [
      ...generalInvoices.map(item => ({
        amount: item.amount,
        discount: item.discount,
        unitPrice: item.item?.price,
        qty: item.quantity,
        vat: item.tax?.rate || 0,
      })),
      ...formData
    ];

    // Calculate the total amount
    const calculatedTotalAmount = mergedData.reduce(
      (acc, curr) => acc + (parseFloat(curr.amount) || 0),
      0
    );

    // Calculate the total discount
    const calculatedDiscount = mergedData.reduce(
      (acc, curr) => acc + (parseFloat(curr.discount) || 0),
      0
    );


    // Calculate the total VAT and total initial price
    const calculatedTotalVat = mergedData.reduce((acc, curr) => {
      const unitPrice = parseFloat(curr.unitPrice) || 0;
      const qty = parseFloat(curr.qty) || 0;
      // const discountPercentage = parseFloat(curr.discountPercentage?.replace('%', '') || 0);
      const discountAmount = parseFloat(curr.discount) || 0;

      const vatRate = curr.vat?.rate
        ? parseFloat(curr.vat.rate) || 0
        : parseFloat(curr.vat) || 0;

      // Calculate the initial price
      const initialPrice = (unitPrice * qty) - discountAmount;
      // Calculate VAT based on the initial price
      const vatAmount = initialPrice * (vatRate / 100);

      return acc + vatAmount;
    }, 0);



    // Set the total values
    setTotalAmount(calculatedTotalAmount.toFixed(2));
    setTotalDiscount(calculatedDiscount.toFixed(2));
    setTotalTax(calculatedTotalVat.toFixed(2));
    // setTotalPrice(calculatedInitialPrice.toFixed(2)); // Assuming you want to set this value
  }, [generalInvoices, formData]);






  useEffect(() => {
    const calculatedDebit = parseFloat(debitAmount || 0)
    setTotalDebit(calculatedDebit.toFixed(2))
  }, [debitAmount]);


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const isRowEmpty = (row) => {
    return (
      !row.items?.value &&
      !row.qty &&
      !row.amount &&
      !row.vat?.value &&
      !row.discount &&
      !row.discountPercentage
    );
  };


  const filteredFormData = formData.filter(row => !isRowEmpty(row));



  const createSalesInvoice = async () => {
    setCreateLoading(true);

    try {
      const formDatas = new FormData();

      const filteredFormData = formData.filter(row => !isRowEmpty(row));
      // Merge generalInvoices and formData
      const mergedData = [
        ...generalInvoices.map(item => ({
          itemId: item.item.id,
          quantity: item.quantity,
          amount: item.amount,
          tax: item.tax ? item.tax_id : null,
          discount: item.discount,
          discountPercentage: item.discount_percentage
        })),
        ...filteredFormData.map(row => ({
          itemId: row.items.value || '',
          quantity: row.qty || '',
          amount: row.amount || '',
          tax: row.vat.value || '',
          discount: row.discount || '',
          discountPercentage: row.discountPercentage?.replace('%', '') || ''
        }))
      ];

      // Extract data for the API request
      const items = mergedData.map(item => item.itemId);
      const quantities = mergedData.map(item => item.quantity);
      const amounts = mergedData.map(item => item.amount);
      const taxes = mergedData.map(item => item.tax); // Use tax id here
      const discounts = mergedData.map(item => item.discount);
      const discountPercents = mergedData.map(item => item.discountPercentage);

      // Log the arrays to ensure correct formatting
      console.log(selectedSource, selectedMode, discountPercents, discounts, description, items, quantities, amounts, taxes, totalAmount, sICode, selectedCustomer, totalTax, totalDiscount, selectedDate);

      items.forEach((item, index) => {
        formDatas.append(`item_id[${index}]`, item);
      });
      quantities.forEach((quantity, index) => {
        formDatas.append(`quantity[${index}]`, quantity);
      });
      amounts.forEach((amount, index) => {
        formDatas.append(`item_amount[${index}]`, amount);
      });
      taxes.forEach((tax, index) => {
        formDatas.append(`tax_id[${index}]`, tax ?? '');
      });
      discounts.forEach((discount, index) => {
        formDatas.append(`discount[${index}]`, discount);
      });
      discountPercents.forEach((discountPercentage, index) => {
        formDatas.append(`discount_percentage[${index}]`, discountPercentage);
      });
      formDatas.append('transaction_date', selectedDate);
      formDatas.append('order_id', selectedMode);
      formDatas.append('mode', selectedSource);
      formDatas.append('amount', totalAmount);
      formDatas.append('description', description);
      formDatas.append('invoice_number', sICode);
      formDatas.append('supplier_id', selectedCustomer);
      formDatas.append('total_vat', totalTax);
      formDatas.append('total_discount', totalDiscount);
      formDatas.append('currency', selectedCurrency.value || '');
      formDatas.append('supporting_document', selectedImage);

      const response = await axios.post(
        `${BASE_URL}/post-purchase-invoice`,
        formDatas,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response.data?.message, "heeee");
      setSICode("");
      setSelectedCustomer("");
      setDebitCode("");
      setSelectedAccountName("");
      setDebitAmount("");
      setDescription("");
      setSelectedDate("");
      setSelectedSource("");
      handleSource({ value: "select" });
      setSelectedMode("");
      setFormData([]);
      setMod([]);
      fetchInvoiceCode();
      setGeneralInvoices([]);
      setSalesRep('');
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setCreateLoading(false);
    }
  };



  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };


  const fetchGlMethod = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setGlMethod(resultss);

      // console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/beneficiary`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const custome = response.data?.data;
      const customs = custome.map(item => ({
        label: item.name,
        value: item.id
      }));
      setCustomerList(customs);
      // setAddress(custome);

      // console.log(custome, "itss");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchGlMethod();
      fetchCustomers();
    }
  }, [bearer]);




  const fetchInvoiceCode = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setSICode(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);




  const goBack = () => {
    navigate(-1);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };





  const addRow = () => {
    const newRow = {
      items: '', unitPrice: '', qty: '', vat: '', discountPercentage: '', discount: '', totalPrice: ''
    };
    setFormData([...formData, newRow]);
  };

  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };



  const deleteRow2 = (index) => {
    const updatedGeneralInvoices = generalInvoices.filter((_, i) => i !== index);
    setGeneralInvoices(updatedGeneralInvoices);
  }








  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const itemss = response.data?.data;

      const options1 = itemss.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setItemList(itemss);
      setSelectOptions1(options1);
      console.log(itemss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDebitAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTax = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/taxes/company-tax`, { headers });
      const itemss = response.data?.data;

      const seTax = itemss.map((item) => ({
        label: `${item.short_name} - ${item.rate}`,
        value: item.id,
        rate: item.rate
      }));
      setTaxes(seTax);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTaxes([]);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchSalesRep = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/sales_rep`, { headers });
      const itemss = response.data?.data;

      const sales = itemss.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setSalesRep(sales);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setSalesRep([]);
    } finally {
      setIsLoading(false);
    }
  };



  const fetchSource = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/pending-purchase-orders-id?id=${selectedMode}`, { headers });
      let genInv = response.data?.data[0]?.general_invoice;

      // Ensure genInv is an array
      if (!Array.isArray(genInv)) {
        genInv = genInv ? [genInv] : [];
      }

      console.log(response);

      // Update the state only if there is data
      if (genInv.length > 0) {
        setGeneralInvoices(genInv);
      } else {
        setGeneralInvoices([]);
      }

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(selectedSource, "rrr");

  const fetchModes = async () => {
    setIsLoading(true);
    console.log(selectedSource, "jldkj");
    try {
      const response = await axios.get(`${BASE_URL}/pending-purchase-orders?mode=${selectedSource}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response, "hehe");
      const itemss = response.data?.data;

      const modde = itemss.map((item) => ({
        label: `${item.uuid} - ${item.supplier?.name}`,
        value: item.id,
        customer: item.supplier?.name,
        customerId: item.supplier?.id,
        reference: item.reference,

      }));

      setMod(modde);
      if (itemss.length === 0) {
        setSelectedMode(null);
      }


    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(error);
      setMod([]);
      setSelectedMode(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedMode !== null) {
      fetchSource();
    }
  }, [selectedMode]);


  useEffect(() => {
    if (selectedSource !== null) {
      fetchModes();
    }
  }, [selectedSource]);





  const handleItemDescriptionChange = (selectedOption, rowIndex) => {
    const selectedItemId = selectedOption.value;
    const selectedItem = itemList.find(item => item.id === selectedItemId);
    const selectedUnitPrice = selectedItem?.cost_price || 0;
    handleFormChange(selectedOption, "items", rowIndex);
    handleFormChange(selectedUnitPrice, "unitPrice", rowIndex);
  };

  const handleFormChange = (value, fieldName, rowIndex) => {
    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      const currentRow = updatedFormData[rowIndex];

      // Update the field that triggered the change
      if (fieldName === 'vat') {
        // If vat is updated, store the whole tax item
        currentRow[fieldName] = value;
      } else {
        currentRow[fieldName] = value;
      }

      // Calculate total price based on qty and unitPrice
      const qty = parseFloat(currentRow.qty) || 0;
      const unitPrice = parseFloat(currentRow.unitPrice) || 0;
      let initialPrice = qty * unitPrice;

      // Calculate discount
      let discountAmount = 0;
      if (fieldName === 'discountPercentage' || fieldName === 'discount') {
        if (fieldName === 'discountPercentage') {
          const discountPercentage = parseFloat(value.replace('%', '')) || 0;
          discountAmount = (discountPercentage / 100) * initialPrice;
          currentRow.discount = discountAmount.toFixed(2);
        } else if (fieldName === 'discount') {
          discountAmount = parseFloat(value) || 0;
          const discountPercentage = (discountAmount / initialPrice) * 100;
          currentRow.discountPercentage = discountPercentage.toString();
        }
      } else {
        const discountPercentage = parseFloat(currentRow.discountPercentage.replace('%', '')) || 0;
        discountAmount = (discountPercentage / 100) * initialPrice;
        currentRow.discount = discountAmount.toFixed(2);
      }

      initialPrice -= discountAmount; // Apply discount to initial price


      // Calculate VAT and add to initial price
      const selectedTax = currentRow.vat;
      const vatRate = selectedTax ? parseFloat(selectedTax.rate) || 0 : 0;
      const vatAmount = (vatRate / 100) * initialPrice;
      const totalPrice = initialPrice + vatAmount;

      currentRow.amount = totalPrice.toFixed(2);

      return updatedFormData;
    });
  };



  const fetchAcctName = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setAccountName(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchAcctName();
      fetchItems();
      fetchTax();
      fetchSalesRep();
    }
  }, [bearer]);


  const formatNumber = (value) => {
    if (!value) return '';
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const parseNumber = (value) => {
    return value.replace(/,/g, '');
  };

  const source = [
    { id: "select", name: "Select a Source" },
    { id: "", name: "No Source" },
    { id: "1", name: "Purchase Order" },

  ];

  const sources = source.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleSource = (selectedOption) => {
    setSelectedSource(selectedOption.value);
    // setCustomerBalance(null);
    setCustomerName(null);
    setSelectedMode('');
    setDescription('');

    if (selectedOption.value === "") {
      setGeneralInvoices([]);
    } else {
      setGeneralInvoices([]);
    }
  };



  const handleMode = (selectedOption) => {
    setSelectedMode(selectedOption.value);
    setCustomerName(selectedOption.customer);
    setSelectedCustomer(selectedOption.customerId);
    setDescription(selectedOption.reference);

  };

  const extendedCustomerList = [
    { label: '(Add a new supplier)', value: 'add-new' },
    ...customerList
  ];

  const createCustomer = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/beneficiary/add`,
        {
          name: fullName,
          email: email,
          phone_number: phone,
          address: address,
          bank_name: bankName,
          account_name: accName,
          account_number: accNo
        },
        { headers }
      );
      console.log(response.data.message)
      const newCustomer = {
        label: response.data.data.name,
        value: response.data.data.id
      };

      console.log(newCustomer);

      setCustomerList([...customerList, newCustomer]);
      setSelectedCustomer(newCustomer.value);

      fetchCustomers();
      setFullName('');
      setEmail('');
      setPhone('');
      setAddress('');
      setBankName('');
      setAccName('');
      setAccNo('');

      handleClose();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      const errorStatus = error.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: JSON.stringify(error.response.data.message),
      });
      // toast.error(JSON.stringify(error.response.data.message));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getLabel = () => {
    switch (selectedSource) {
      case "1":
        return "Order Number";
      default:
        return "Select Option";
    }
  };


  const fetchCurrency = async () => {
    setCurrLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-all-currencies`, { headers });
      const results = response.data?.data;
      // console.log(results, "currencis")
      const options = results.map((item) => ({
        label: item.code,
        value: item.id,
        symbol: item.symbol
      }));
      setCurrency(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCurrency([]);
    } finally {
      setCurrLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCurrency();
    }
  }, [bearer]);



  const itemsExist = generalInvoices.length > 0;

  console.log(generalInvoices, "FLKDHSLFHD");





  return (
    <div>
      <ToastContainer />
      <LatestDash />

      <div className={classes.finishedbodyCont}>



        <h3 className={classes.productText}>Create Purchases Invoice</h3>
        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            <div className={classes.formLabel}>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Customer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      // autoFocus
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      // autoFocus
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Office Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter office Address"
                      // autoFocus
                      value={officeAddress}
                      onChange={(e) => setOfficeAddress(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone Number"
                      // autoFocus
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      // autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Supplier's Bank Name"
                      // autoFocus
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Account Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Supplier's Account Name"
                      // autoFocus
                      value={accName}
                      onChange={(e) => setAccName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Supplier's Account Number"
                      // autoFocus
                      value={accNo}
                      onChange={(e) => setAccNo(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createCustomer}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>


            <div className="d-flex justify-content-center">
              <Form style={{ marginTop: 20, width: '90%' }}>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Source</Form.Label>
                        <div style={{ width: 500 }}>
                          <Select isLoading={loading} options={sources} placeholder='Select Source' onChange={handleSource}
                            menuPortalTarget={document.body}
                            styles={{

                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                maxWidth: '700px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>{getLabel()}</Form.Label>
                        <div style={{ width: 500 }}>
                          <Select loading={loading} options={mod} placeholder='Select Invoice' onChange={handleMode}
                            menuPortalTarget={document.body}
                            styles={{

                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                maxWidth: '300px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>

                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Invoice To</Form.Label>
                        <div style={{ width: 500, display: "flex", flexDirection: "column" }}>
                          {customerName ? (
                            <Form.Control
                              className="formControl"
                              type="text"
                              value={customerName}
                              disabled
                            />
                          ) : (
                            <Select
                              loading={loading}
                              options={extendedCustomerList}
                              placeholder='Select Customer'
                              onChange={handleCustomerChange}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                menu: (provided) => ({
                                  ...provided,
                                  maxHeight: '300px',
                                  maxWidth: '700px',
                                  overflowY: 'auto',
                                }),
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    {/* {customerBalance !== null && (
      <div style={{ color: 'red', fontSize: 12, marginTop: -15, marginLeft: '25%' }}>
        Outstanding Balance: {parseFloat(customerBalance).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
      </div>
    )} */}
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Invoice Code</Form.Label>
                        <Form.Control
                          className={classes.formControl}
                          type="text"
                          // placeholder="Enter Description of the Product"
                          value={sICode}
                          // onChange={setSICode}
                          disabled
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          name="description"
                          style={{ width: "500px" }}
                        />

                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Date</Form.Label>
                        <input
                          className="form-control"
                          required=""
                          type="date"
                          onChange={handleDateChange}
                          name="date"
                          value={selectedDate}
                          style={{ width: "500px" }}
                        />

                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Currency</Form.Label>
                        {/* {formData.map((row, index) => ( */}
                        {/* // <tr > */}
                        {/* <td style={{ width: "200px" }}> */}
                        <div style={{ width: 500 }}>
                          <Select
                            value={selectedCurrency}
                            onChange={setSelectedCurrency}
                            options={selectOptions}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                // maxWidth: '200px',
                                overflowY: 'auto',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Supporting Doc.</Form.Label>
                        {/* {formData.map((row, index) => ( */}
                        {/* // <tr > */}
                        {/* <td style={{ width: "200px" }}> */}
                        <div style={{ width: 500 }}>
                        <Form.Control
                            type="file"
                            placeholder="Choose file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={handleImageChange}
                            style={{ width: 349, display: "flex", justifyContent: "left" }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {/* <div className="row" style={{ marginTop: 5 }}>

                <div className="col-md-12">
                  <Form.Group className="mb-3">
                    <div className="d-flex align-items-center form-group-custom">
                      <Form.Label className={classes.formLabelCustom}>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        name="description"
                        style={{ width: "1200px" }}
                      />

                    </div>
                  </Form.Group>
                </div>
              </div> */}

                {/* <div className="row" style={{   }}>
                <div className="col-md-6">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="d-flex align-items-center form-group-custom">
              <Form.Label className={classes.formLabelCustom}>Sales Rep</Form.Label>
              <div style={{width: 500}}>
              {repName ? (
      <Form.Control
        className="formControl" 
        type="text"
        value={repName}
        disabled
      />
    ) : (
      <Select
      menuPortalTarget={document.body}
      
      styles={{
        width: 355,
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menu: (provided) => ({
          ...provided,
          maxHeight: '200px',
          maxWidth: '700px',
          overflowY: 'auto',
        }),
      }}
      isLoading={loading} options={salesRep} placeholder='Select Sales Rep' onChange={handleSalesChange}  />
    )}
                     
                    </div>
            </div>
          </Form.Group>
        </div>
      
      </div> */}

              </Form>
            </div>
            <div className={classes.buttonsGroup}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="row" style={{ maxWidth: "100%", marginTop: 30 }}>
                  <h5 style={{ textAlign: "left" }}>Add Item(s)</h5>
                  {/* <div className="col-md-6"> */}
                  <div className={`table-responsive ${classes.tablemobile123}`} >
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                      <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                        <tr>
                          <th style={{ width: "200px" }}>Item</th>
                          <th style={{ width: '100px' }}>Unit Price(N)</th>
                          <th style={{ width: '150px' }}>Qty</th>
                          <th style={{ width: '10px' }}>Discount(%)</th>
                          <th style={{ width: 150 }}>Discount</th>
                          <th style={{ width: 100 }}>V.A.T</th>
                          <th>Total Price(N)</th>
                          <th>
                            <div style={{}}>
                              <div className="btn btn-sm printbtninv" onClick={() => addRow()}>
                                <i className="fas fa-plus" style={{ color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: "nowrap" }}>
                        {itemsExist ? (
                          generalInvoices.map((item, index) => (
                            <tr key={index}>
                              <td style={{ width: "100px", height: 0, paddingBottom: 0 }}>
                                {item?.item?.name}
                              </td>
                              <td style={{ textAlign: "right" }}>{parseFloat(item?.item?.cost_price).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}</td>
                              <td style={{ width: "40px", height: 0, paddingBottom: 0 }}>
                                {item?.quantity}
                              </td>
                              <td style={{ textAlign: "right" }}>{item.discount_percentage}</td>
                              <td style={{ textAlign: "right" }}>{parseFloat(item?.discount).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}</td>
                              <td style={{ width: "100px", height: 0, paddingBottom: 0 }}>
                                {`${item?.tax?.short_name === undefined ? "" : item?.tax?.short_name} - ${item.tax?.rate === undefined ? "" : item.tax?.rate}`}
                              </td>

                              <td style={{ textAlign: "right" }}>{parseFloat(item?.amount).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}</td>
                              <td style={{ textAlign: "center" }}>
                                <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow2(index)}>
                                  <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          null
                        )}
                        {formData.map((row, index) => (
                          <tr key={index}>
                            <td style={{ width: "200px" }}>
                              <div style={{ width: 200 }}>
                                <Select
                                  value={row.items}
                                  onChange={(selectedOption) => handleItemDescriptionChange(selectedOption, index)}
                                  options={selectOptions1}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                      ...provided,
                                      maxHeight: '300px',
                                      // maxWidth: '200px',
                                      overflowY: 'auto',
                                    }),
                                  }}
                                />
                              </div>
                            </td>
                            <td style={{ width: '100px' }}>
                              <input
                                type="text"
                                name={`rowUnitPrice ${index}`}
                                decimalsLimit={2}
                                value={row.unitPrice}
                                className="form-control"
                                onChange={(e) => handleFormChange(e.target.value, "unitPrice", index)}
                                // disabled
                                style={{ textAlign: "right", border: "none", width: '7rem' }}
                              />
                              {/* <CurrencyInput
                  name={`rowUnitPrice ${index}`}
                  decimalsLimit={2}
                  value={row.unitPrice}
                  className="form-control"
                  disabled
                  style={{ textAlign: "right", border: "none", width: '7rem' }}
                /> */}
                            </td>
                            <td style={{ width: '150px' }}>
                              <input
                                type="text"
                                className="form-control"
                                value={row.qty}
                                onChange={(e) => handleFormChange(e.target.value, "qty", index)}
                                // onBlur={calculateFormData}
                                style={{ width: '70px' }}
                              />
                            </td>
                            <td style={{ width: '10px' }}>
                              <input
                                type="text"
                                className="form-control"
                                value={row.discountPercentage}
                                onChange={(e) => handleFormChange(e.target.value, "discountPercentage", index)}
                                onBlur={(e) => {
                                  const percentage = parseFloat(e.target.value.replace('%', '')) || 0;
                                  handleFormChange(percentage + '%', "discountPercentage", index);
                                }}
                                onFocus={(e) => {
                                  handleFormChange(row.discountPercentage.replace('%', ''), "discountPercentage", index);
                                }}
                                style={{ width: '80px' }}
                              />
                            </td>
                            <td style={{ width: '100px' }}>
                              <input
                                type="text"
                                className="form-control"
                                value={row.discount}
                                onChange={(e) => handleFormChange(e.target.value, "discount", index)}
                                // onFocus={(e) => {
                                //   const cleanValue = parseNumber(e.target.value);
                                //   e.target.value = cleanValue;
                                // }}
                                // onBlur={(e) => {
                                //   const formattedValue = formatNumber(e.target.value);
                                //   e.target.value = formattedValue;
                                //   handleFormChange(e.target.value, "discount", index);
                                // }}
                                style={{ textAlign: "right", border: "none", width: '7rem' }}
                              />
                            </td>
                            <td style={{ width: "100px" }}>
                              <div style={{ width: 150 }}>
                                {/* <Form.Select
  name="tax" 
  className="form-control" 
  required 
  value={row.vat} 
  onChange={(e) => handleFormChange(e.target.value, "vat", index)} 
  style={{width: "100px"}}
>
  <option value="">Select</option>
  {taxes.map((item) => (
    <option key={item.id} value={item.id}>
      {item.short_name} - {item.rate}
    </option>
  ))}
</Form.Select> */}

                                <Select
                                  onChange={(selectedOption) => handleFormChange(selectedOption, "vat", index)}
                                  options={taxes}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                      ...provided,
                                      maxHeight: '300px',
                                      overflowY: 'auto',
                                    }),
                                  }}
                                />
                              </div>
                            </td>

                            <td style={{ width: '7rem' }}>
                              <CurrencyInput
                                name={`rowLineTotal ${index}`}
                                decimalsLimit={2}
                                value={row.amount}
                                className="form-control"
                                disabled
                                style={{ textAlign: "right", border: "none", width: '8rem' }}
                              />
                            </td>
                            <td style={{ textAlign: "center", width: "2rem" }}>
                              <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow(index)}>
                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
              <div className={classes.flexdirectionmessage} >
                <div className="col-md-6" style={{ marginLeft: '10px' }}>
                  <Form.Group className="mb-3">
                    <div className="d-flex align-items-center form-group-custom">
                      <Form.Label className={classes.formLabelCustom}>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        // value={description}
                        // onChange={(e) => setDescription(e.target.value)}
                        name="description"
                        style={{ width: "1000px" }}
                      />

                    </div>
                  </Form.Group>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: 10, justifyContent: 'flex-end' }}>
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', gap: '5px' }}>
                    <label for="example-text-input" style={{ textAlign: 'right' }}>Total V.A.T:</label>
                    <div style={{ padding: '0', width: '10rem', }}>


                      <CurrencyInput
                        name='total-amount'
                        decimalsLimit={2}
                        value={totalTax}
                        className="form-control"
                        disabled
                        style={{ textAlign: "right", border: "none", width: '10rem' }}
                      />

                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', gap: '5px' }}>
                    <label for="example-text-input" >Total Discount:</label>
                    <div style={{ padding: '0' }}>
                      <CurrencyInput
                        name='total-amount'
                        decimalsLimit={2}
                        value={totalDiscount}
                        className="form-control"
                        disabled
                        style={{ textAlign: "right", border: "none", width: '10rem' }}
                      />

                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end", gap: '5px', alignItems: 'center', }}>
                    <label for="example-text-input" >Total Amount:</label>
                    <div style={{ padding: '0' }}>
                      <CurrencyInput
                        name='total-amount'
                        decimalsLimit={2}
                        value={totalAmount}
                        className="form-control"
                        disabled
                        style={{ textAlign: "right", border: "none", width: '10rem' }}
                      />

                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 50 }} className={classes.bttn}>
                <Button variant="light" className={classes.btn1} onClick={goBack}> Cancel</Button>
                <Button style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {createLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button disabled style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {/* {createLoading ? (
                          <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                          </>
                        ) : (
                          "Send to Mail"
                        )} */}
                  Send to Mail
                </Button>
                <Button disabled style={{ borderRadius: 5 }} variant='success' onClick={createSalesInvoice}>
                  {/* {createLoading ? (
                          <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                          </>
                        ) : (
                          "Print in PDF"
                        )} */}
                  Print in PDF
                </Button>
              </div>
            </div>









          </div>
        </div>

      </div>






    </div >
  )
}