import React from 'react';
import classes from './SalesReceiptts.module.css';
import { useLocation } from 'react-router-dom';
import Logo from "../../../assets/promix/logoGreen.png";

const SalesReceiptts = () => {
  const location = useLocation();
  const { products } = location.state || { products: [] };
  
  // Calculate sub-total by summing up (quantity * price) for each product
  const subTotal = products.reduce(
    (total, product) => total + product.selectedQuantity * parseFloat(product.price),
    0
  );

  const VAT_RATE = 0.075; // 7.5%
  const vatAmount = subTotal * VAT_RATE;
  const grandTotal = subTotal + vatAmount;

  return (
    <div className={classes.invoiceContainer}>
      <header className={classes.header}>
        <div className={classes.logo}>
        <img src={Logo} alt="Icon" className={classes.logoStyle} />
        </div>
        <div className={classes.companyInfo}>
          <p className={classes.promixHead}>Promix Accounting</p>
          <div className={classes.companyT}>
            <p>No 54, Olusegun Osoba Way, Oke-Ilewo,</p>
            <p>Abeokuta, Ogun state, Nigeria.</p>
            <p>promix@gmail.com</p>
            <p>08090010022</p>
          </div>
        </div>
      </header>

      <h2 className={classes.title}>INVOICE</h2>

      <div className={classes.billDiv}>
        <section className={classes.billTo}>
          <p className={classes.billT}>Bill to:</p>
          <div className={classes.billText}>
            <p>James Olalekan</p>
            <p>Lafenwa, Abeokuta</p>
            <p>08020902360</p>
          </div>
        </section>

        <p className={classes.dateIssued}>Date issued: 03/12/2024</p>
      </div>

      <table className={classes.table}>
        <thead>
          <tr>
            <th>S/N</th>
            <th>ITEM NAME</th>
            <th style={{textAlign:'center'}}>QTY</th>
            <th style={{textAlign:'right'}}>RATE</th>
            <th style={{textAlign:'right'}}>AMOUNT</th>
          </tr>
        </thead>
        <tbody className={classes.tableSy}>
          {products.map((product, index) => (
            <tr key={product.id}>
              <td>{index + 1}</td>
              <td>{product.name}</td>
              <td style={{textAlign:'center'}}>{product.selectedQuantity}</td>
              <td style={{textAlign:'right'}}>{parseFloat(product.price).toLocaleString()}</td>
              <td style={{textAlign:'right'}}>{(product.selectedQuantity * parseFloat(product.price)).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <section className={classes.totals}>
        <p>Sub total: {subTotal.toLocaleString()}</p>
        <p>VAT: {vatAmount.toLocaleString()}</p>
        <p>Total: {grandTotal.toLocaleString()}</p>
      </section>

      <section className={classes.notes}>
        <h4>Notes</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </section>
    </div>
  );
};

export default SalesReceiptts;
