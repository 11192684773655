import React, { useState, useEffect } from 'react';
import classes from '../ExchangeRate/ExchangeRate.module.css';
// import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Tab, Tabs } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import Select from 'react-select';
import { ratingClasses } from '@mui/material';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TaxModal from './TaxModal';
import { useModal } from './GlobalContext';




export default function Tax() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [archivedData, setArchivedData] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [provinceLoading, setProvinceLoading] = useState(false);
  const [currLoading, setCurrLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  // const handleClose = () => setShow(false);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShow1(false);
  // const handleShow = () => setShow(true);
  // const handleShow = () => setShowModal(true);
  const handleShow1 = () => setShow1(true);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [eyeClicked1, setEyeClicked1] = useState(false);
  const [trashClicked1, setTrashClicked1] = useState(false);
  const [eyeClicked2, setEyeClicked2] = useState(false);
  const [trashClicked2, setTrashClicked2] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTax, setSelectedTax] = useState("");
  const [entriesPerPage1, setEntriesPerPage1] = useState(100);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [entriesPerPage2, setEntriesPerPage2] = useState(100);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [description, setDescription] = useState('')
  const [description1, setDescription1] = useState('')
  const [accountantName, setAccountantName] = useState("");
  const [accountantName1, setAccountantName1] = useState("");
  const [accountantPhone, setAccountantPhone] = useState("");
  const [accountantPhone1, setAccountantPhone1] = useState("");
  const [accountantEmail, setAccountantEmail] = useState("");
  const [accountantEmail1, setAccountantEmail1] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [rates, setRates] = useState('');
  const [shortname, setShortName] = useState('');
  const [rates1, setRates1] = useState('');
  const [selectedReport1, setSelectedReport1] = useState('');
  const [shortname1, setShortName1] = useState('');
  const [roleLoading, setRoleLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { handleShow } = useModal();



  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      // if (value3 !== null) {
      //   setAdmin(value3);
      // }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchRates = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/taxes/company-tax`, { headers });

      // console.log(response);
      const results = response.data?.data;
      setTableData(results);
      console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchArchivedData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/taxes/fetch-softdelete`, { headers });
      const results = response.data?.data;
      // const resultx = response.data?.data?.total;
      // setTotalEntries(resultx);
      setArchivedData(results);
      console.log(response, "SLFSLK");
      // const total = response.data?.data?.last_page || 1;
      // setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setArchivedData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchRates();
      fetchArchivedData();

    }
  }, [bearer]);

  const fetchCurrency = async () => {
    setCurrLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-all-currencies`, { headers });
      const results = response.data?.data;
      // console.log(results, "currencis")
      const options = results.map((item) => ({
        label: item.code,
        value: item.id,
        symbol: item.symbol
      }));
      setCurrency(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCurrency([]);
    } finally {
      setCurrLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCurrency();
    }
  }, [bearer]);


  const createRate = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/taxes/create`,
        {
          description: description,
          short_name: shortname,
          rate: rates
        },
        { headers }
      );

      console.log(response)

      setDescription('');
      setShortName('');
      setRates('');
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      // toast.success(response.data.message);
      console.log(response.data);

      fetchRates();
      fetchArchivedData();
      handleClose();

      toast.success(response.data.message);
      console.log(response);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };


  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption.value);
  };




  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }




  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  // const filteredData1 = tableData1.filter(item => item.description.toLowerCase().includes(searchTerm1.toLowerCase()));

  // const totalPages1 = Math.ceil(filteredData1.length / entriesPerPage1);



  // const totalEntries1 = filteredData1.length;
  // const startIndexx1 = (currentPage1 - 1) * entriesPerPage1 + 1;
  // const endIndexx1 = Math.min(startIndexx1 + entriesPerPage1 - 1, totalEntries1);
  // const displayedData1 = filteredData1.slice(startIndexx1 - 1, endIndexx1);

  // const filteredData2 = tableData2.filter(item => item.description.toLowerCase().includes(searchTerm2.toLowerCase()));

  // const totalPages2 = Math.ceil(filteredData2.length / entriesPerPage2);



  // const totalEntries2 = filteredData2.length;
  // const startIndexx2 = (currentPage2 - 1) * entriesPerPage2 + 1;
  // const endIndexx2 = Math.min(startIndexx2 + entriesPerPage2 - 1, totalEntries2);
  // const displayedData2 = filteredData2.slice(startIndexx2 - 1, endIndexx2);


  const options1 = tableData2.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      setTableData2(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      // fetchData2();
      fetchGl();
    }
  }, [bearer]);


  const handleEyeClick = (id) => {    
    
    const foundTax = tableData.find(item => item.id === id);
    
    const { report_gl } = foundTax;

    const selectedReport = options1.find(item => item.value === report_gl?.id);
    setSelectedReport1(selectedReport);
 
    setSelectedTax(foundTax);
    setDescription1(foundTax?.description || '');
    setShortName1(foundTax?.short_name || '');
    setRates1(foundTax?.rate || '');
    setShow1(true);
    setEyeClicked(true);
  };


  const handleEyeClick1 = (id) => {
    const foundTax = archivedData.find(item => item.id === id);
    setSelectedTax(foundTax);
    setDescription1(foundTax?.description || '');
    setShortName1(foundTax?.short_name || '');
    setRates1(foundTax?.rate || '');
    setShow1(true);
    setEyeClicked(true);
  };




  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to archive this tax.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, archive it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/taxes/delete?id=${id}`, { headers });
      fetchRates();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleTrashClick1 = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this tax.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/taxes/delete-single-softdelete?id=${id}`, { headers });
      fetchRates();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleTrashClick2 = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to restore this tax.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, restore it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/taxes/restore-single-softdelete?id=${id}`, { headers });
      fetchRates();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };




  const editContinent = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/taxes/update`,
        {
          description: description1,
          short_name: shortname1,
          rate: rates1,
          id: selectedContinent.id,
          report_gl: selectedReport1.value || ''

        },
        { headers }
      );

      // fetchContinents();
      handleClose1();
      fetchRates();
      fetchArchivedData();
      toast.success(response.data.message);

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <LatestDash />

      <div className={classes.finishedbodyCont}>
        {/* <h3 style={{marginTop: '5rem', fontWeight: 700, fontSize: 30}}>Manage Tax</h3> */}
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginTop: '70px' }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Manage Tax</h3>
            <p style={{ margin: " 0 10px 20px 15px" }}>Add, View, Edit, and Delete Tax</p>
          </div>
          <div className={`${classes.formIntBtn} ${classes.formIntBtnUnitss}`} style={{ zIndex: 1, }}>
            <OverlayTrigger
              placement="top"
              className={classes.btndf1}
              overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new tax</Tooltip>}
            >
              <Button className={classes.btn1} variant="success" onClick={handleShow}>
                Add New Tax
              </Button>
            </OverlayTrigger>
          </div>
        </div>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>
            {/*            
           <div className={classes.loandethead}>
             <div className={classes.formLabel}>
             </div>
             <div className={classes.formIntBtn}>
             <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to add new tax</Tooltip>}
                  >
                   <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Tax</Button>
                  </OverlayTrigger>

             </div>
           </div> */}
            {/* <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Create Tax</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />

                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Short Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Short Name"
                      // autoFocus
                      value={shortname}
                      onChange={(e) => setShortName(e.target.value)}
                    />

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Rate</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Rate"
                      // autoFocus
                      value={rates}
                      onChange={(e) => setRates(e.target.value)}
                    />

                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createRate}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating New Tax, Please wait...</span>
                    </>
                  ) : (
                    "Create New Tax"
                  )}
                </Button>
              </Modal.Footer>
            </Modal> */}
            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Tax</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={description1}
                      onChange={(e) => setDescription1(e.target.value)}
                    />

                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Short Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Short Name"
                      // autoFocus
                      value={shortname1}
                      onChange={(e) => setShortName1(e.target.value)}
                    />

                  </Form.Group>

                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Rate</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Rate"
                      // autoFocus
                      value={rates1}
                      onChange={(e) => setRates1(e.target.value)}
                    />
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Report to</Form.Label>
                      <Select loading={isLoading} dropdownHeight='100px' options={options1}
                        placeholder="Select Account" onChange={setSelectedReport1} value={selectedReport1}
                        className={classes.formSelect}
                      />
                    </Form.Group>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>
                <Button variant="success" onClick={editContinent} >
                  {loading ? (<> <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>
                      Updating, Please wait...
                    </span>
                  </>) : ("Save Changes")}
                </Button>

              </Modal.Footer>
            </Modal>

            <Tabs
              defaultActiveKey="Tax"
              id="uncontrolled-tab-example"
              className={`mb-3 complete-tabs ${classes.ikatabsika}`}
              variant="underline"
              color="#2D995F"
            >
              <Tab eventKey="Tax" title="Tax" className={classes.ikatabsika11}>
                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                  <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                    <div className={classes.actionsBtns}>
                      <Button variant='success'>Copy</Button>
                      <Button variant='success'>Excel</Button>
                      <Button variant='success'>PDF</Button>
                      <Button variant='success' className={classes.diffbtn}>Column visibility</Button>
                    </div>
                    <div className={classes.show}>
                      <label className="d-flex justify-content-start align-items-center">
                        Show
                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                          value={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(parseInt(e.target.value));
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                  <div className="text-right modal-effect ">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                 {searchLoading ? (
                     <>
                     <Spinner  size='sm' /> 
                    
   </>
 ) : (
               "Search"
             )}
                 </Button> */}
                      </div>


                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>
                  {isLoading ? (
                    <p>Fetching Taxes...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">

                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                            <th>ID</th>
                            <th>Description</th>
                            <th>Short Name</th>
                            <th>Rate</th>
                            {/* <th>Specify By</th>
                                      <th>Date</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap', }}>

                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td style={{ paddingLeft: 10 }}>{index + 1}</td>
                              <td style={{ paddingLeft: 10 }}>{item.description}</td>
                              <td style={{ paddingLeft: 10 }}>{item.short_name}</td>
                              <td style={{ paddingLeft: 10 }}>{item.rate}</td>
                              {/* <td style={{paddingLeft: 10}}>{item?.exchange_rate}</td>
                                        <td style={{paddingLeft: 10}}>{item?.specify_by?.name}</td>
                                        <td style={{paddingLeft: 10}}>{formatDate(item?.specify_by?.created_at)}</td> */}
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to edit/update your tax</Tooltip>}
                                >
                                  <div onClick={() => handleEyeClick(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                                    <i class="fas fa-pencil-alt" style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to move your tax to archive</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick(item.id)} style={{ padding: "0", marginRight: 10 }} className="btn btn-danger-soft btn-sm">
                                    <i class="fa fa-archive" style={{ color: "#000", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>


                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to permanently delete your tax</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick1(item.id)} style={{ padding: "0" }} className="btn btn-danger-soft btn-sm">
                                    <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className={classes.endded}>
                  <p>
                    Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                  </p>
                  <div style={{ display: 'flex' }}>
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      // Show only 5 pages or less if available
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>

              </Tab>
              <Tab eventKey="ArchivedTax" title="Deleted or Archived" className={classes.ikatabsika11}>
                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                  <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                    <div className={classes.actionsBtns}>
                      <Button variant='success'>Copy</Button>
                      <Button variant='success'>Excel</Button>
                      <Button variant='success'>PDF</Button>
                      <Button variant='success' className={classes.diffbtn}>Column visibility</Button>
                    </div>
                    <div className={classes.show}>
                      <label className="d-flex justify-content-start align-items-center">
                        Show
                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                          value={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(parseInt(e.target.value));
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                  <div className="text-right modal-effect ">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                 {searchLoading ? (
                     <>
                     <Spinner  size='sm' /> 
                    
   </>
 ) : (
               "Search"
             )}
                 </Button> */}
                      </div>


                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>
                  {isLoading ? (
                    <p>Fetching Taxes...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">

                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                            <th>ID</th>
                            <th>Description</th>
                            <th>Short Name</th>
                            <th>Rate</th>
                            {/* <th>Specify By</th>
                                      <th>Date</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap', }}>

                          {archivedData.map((item, index) => (
                            <tr key={index}>
                              <td style={{ paddingLeft: 10 }}>{index + 1}</td>
                              <td style={{ paddingLeft: 10 }}>{item.description}</td>
                              <td style={{ paddingLeft: 10 }}>{item.short_name}</td>
                              <td style={{ paddingLeft: 10 }}>{item.rate}</td>
                              {/* <td style={{paddingLeft: 10}}>{item?.exchange_rate}</td>
                                        <td style={{paddingLeft: 10}}>{item?.specify_by?.name}</td>
                                        <td style={{paddingLeft: 10}}>{formatDate(item?.specify_by?.created_at)}</td> */}
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to edit/update your tax</Tooltip>}
                                >
                                  <div onClick={() => handleEyeClick1(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                                    <i class="fas fa-pencil-alt" style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to restore your archived tax</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick2(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                                    <i class='fas fa-trash-restore-alt' style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 14 }}></i>
                                  </div>
                                </OverlayTrigger>


                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to permanently delete your tax</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick1(item.id)} style={{ padding: "0" }} className="btn btn-danger-soft btn-sm">
                                    <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className={classes.endded}>
                  <p>
                    Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                  </p>
                  <div style={{ display: 'flex' }}>
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      // Show only 5 pages or less if available
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>

              </Tab>

            </Tabs>


          </div>
        </div>

      </div>






    </div >
  )
}