import React from "react";
import classes from "./OrderNav.module.css";
import Setting from "../../assets/promix/setting.svg";
import Report from "../../assets/promix/report.svg";
import Contact from "../../assets/promix/contact.svg";
import Area from "../../assets/promix/areas.svg";
import Region from "../../assets/promix/region.svg";
import AReport from "../../assets/promix/areport.svg";
import DReport from "../../assets/promix/dreport.svg";
import Schedule from "../../assets/promix/schedule.svg";
import Logo from "../../assets/promix/logoGreen.png";
import { Navigate, useNavigate } from "react-router-dom";

const OrderNav = () => {
    const navigate = useNavigate()
  return (
    <div>
      <div className={classes.topSty}>
        <div className={classes.stinfo}>
          <img src={Logo} alt="Icon" className={classes.logoStyle} />
        </div>
        <div style={{ textAlign: "right" }}>
          <p className={classes.wlc}>Welcome</p>
          <h3 className={classes.userN}>UserName</h3>
        </div>
      </div>
      <div style={{border:'1px solid #1a1a1a',color:'#1a1a1a',opacity:'0.1',marginBottom:10}}></div>
      <div className={classes.divS}>
        <div className={classes.divInner} onClick={() => navigate('/sales_ord')}>
          <img src={Setting} alt="setting" />
          <p className={classes.pos}>Point Of Sales</p>
        </div>
        <div className={`${classes.divInner}`} onClick={() => navigate('/completed_ord')}>
          <img src={Report} alt="report" />
          <p>Completed Transactions</p>
        </div>
        <div className={`${classes.divInner}`} onClick={() => navigate('/failed_ord')}>
          <img src={Contact} alt="contact" />
          <p>Failed Transactions</p>
        </div>
        <div className={`${classes.divInner}`}>
          <img src={Area} alt="arer" />
          <p>Manage Areas</p>
        </div>
        <div className={`${classes.divInner}`}>
          <img src={Region} alt="region" />
          <p>Manage Regions</p>
        </div>
        <div className={`${classes.divInner}`}>
          <img src={AReport} alt="aregion" />
          <p>Fixed Assets Report</p>
        </div>
        <div className={`${classes.divInner}`}>
          <img src={DReport} alt="dregion" />
          <p>Depreciation Report</p>
        </div>
        <div className={`${classes.divInner}`}>
          <img src={Schedule} alt="schedule" />
          <p>Fixed Asset Schedule</p>
        </div>
      </div>
    </div>
  );
};

export default OrderNav;
