import React, { useState, useEffect } from "react";
import classes from "./Salesorders.module.css";
import {
  Spinner,
  Badge,
  Button,
  Modal,
  Form,
  Tab,
  Tabs,
} from "react-bootstrap";
import axios from "axios";
import Logo from "../../../assets/promix/logoGreen.png";
import Setting from "../../../assets/promix/setting.svg";
import Report from "../../../assets/promix/report.svg";
import Contact from "../../../assets/promix/contact.svg";
import Area from "../../../assets/promix/areas.svg";
import Region from "../../../assets/promix/region.svg";
import AReport from "../../../assets/promix/areport.svg";
import DReport from "../../../assets/promix/dreport.svg";
import Arrow from "../../../assets/promix/dArrow-down.svg";
import Schedule from "../../../assets/promix/schedule.svg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CurrencyInput from "react-currency-input-field";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "../../api/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import MainDashoard from "../../Main Dashboard/MainDashoard";
import LatestDash from "../../LatestDashboard/LatestDash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import flakes from "../../../assets/promix/flakes.png";
import nascoflakes from "../../../assets/promix/nascoflakes.png";
import nestle from "../../../assets/promix/Nestle.png";
import nestlemilo from "../../../assets/promix/nestlemilo.png";
import trash from "../../../assets/promix/trash.png";
import { CiSearch } from "react-icons/ci";
import OrderNav from "../../../Component/OrderNav/OrderNav";

export default function CompletedOrder() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState("");
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [stat, setStat] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [tellerNumber, setTellerNumber] = useState("");
  const [roless, setRoless] = useState([]);
  const [archiveData, setArchivedData] = useState([]);
  const [outstanding, setOutstanding] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [amountToPay, setAmountToPay] = useState("");
  const [selectedDebitAccount, setSelectedDebitAccount] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [tableData2, setTableData2] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);
  const [newData, setNewData] = useState([]);
  const [key, setKey] = useState("receipt 1");
  const [counter, setCounter] = useState(0);
  const [opt, setOpt] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [products, setProducts] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = [
    "Point Of Sales Content",
    "Completed Transactions Content",
    "Failed Transactions Content",
    "Manage Areas Content",
    "Manage Regions Content",
    "Fixed Assets Report Content",
    "Depreciation Report Content",
    "Fixed Asset Schedule Content",
  ];

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value2 = await AsyncStorage.getItem("permissions");
      const value3 = await AsyncStorage.getItem("admin");

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-stocks`, {
        headers,
      });
      const roleList = response.data?.data;
      // console.log(roleList);
      setOpt(roleList);
      // setRoless(roleList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/sales-orders`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        // console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };
  const fetchArchivedData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/sales-orders/fetch-softdelete`,
        { headers }
      );
      const results = response.data?.data;
      // console.log(results);
      setArchivedData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        // console.log(errorStatus);
        setArchivedData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData7 = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/sales-orders/total-order-count`,
        { headers }
      );
      const sta = response.data?.data;
      // console.log(sta);
      setStat(sta);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        // console.log(errorStatus);
        setStat([]);
      }
    } finally {
      setLoading(false);
    }
  };
  const fetchData3 = async () => {
    setUpdateLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`,
        { headers }
      );
      const results = response.data?.data;
      // console.log(results);
      setTableData2(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        // console.log(errorStatus);
        setTableData2([]);
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleDebitChange = (event) => {
    setSelectedDebitAccount(event.target.value);
  };

  useEffect(() => {
    if (bearer) {
      fetchData();

      fetchData3();
      fetchData7();
      fetchArchivedData();
    }
  }, [bearer]);

  const filteredData = tableData.filter((item) =>
    item.customer?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const handleEyeClick = (id) => {
    const foundInvoice = tableData.find((item) => item.id === id);
    //  console.log(foundInvoice);
    navigate("/accounting/update_order", { state: { foundInvoice } });

    setEyeClicked(true);
  };
  const handleEyeClick1 = (id) => {
    const foundInvoice = archiveData.find((item) => item.id === id);
    //  console.log(foundInvoice);
    navigate("/accounting/update_order", { state: { foundInvoice } });

    setEyeClicked(true);
  };

  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to archive this sales order.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
      cancelButtonText: "No, cancel",
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(
        `${BASE_URL}/sales-orders/delete?id=${id}`,
        { headers }
      );
      fetchData();
      fetchData7();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        // console.log(errorMessage);
      }
    }
  };
  const handleTrashClick1 = async (id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this sales order.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(
        `${BASE_URL}/sales-orders/delete-single-softdelete?id=${id}`,
        { headers }
      );
      fetchData();
      fetchData7();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        // console.log(errorMessage);
      }
    }
  };
  const handleTrashClick2 = async (id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to restore this sales order.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, restore it!",
      cancelButtonText: "No, cancel",
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(
        `${BASE_URL}/sales-orders/restore-single-softdelete?id=${id}`,
        { headers }
      );
      fetchData();
      fetchData7();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        // console.log(errorMessage);
      }
    }
  };

  const handleCreate = () => {
    navigate("/accounting/create_order");
  };

  const handlePrintInvoice = (id) => {
    const selectedInvoice = tableData.find((item) => item.id === id);

    navigate("/print_sales_order", { state: { selectedInvoice } });
  };

  const handleSalesChange = (selectedOption) => {
    const selectedId = selectedOption.value;

    setSelectedInvoice(selectedOption.value);

    const intselectedId = parseInt(selectedId);
    const selectedInvoice = newData.find((item) => item.id == intselectedId);
    setDescription(selectedInvoice?.description || "");
    setAmount(selectedInvoice?.amount || "");
    setOutstanding(selectedInvoice?.balance || "");
  };

  const formattedAmount = isNaN(parseFloat(amount))
    ? "0.00"
    : parseFloat(amount).toLocaleString("en-US", {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

  const formattedOutstanding = isNaN(parseFloat(outstanding))
    ? "0.00"
    : parseFloat(outstanding).toLocaleString("en-US", {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

  const handleValueChange = (value, name, values) => {
    setAmountToPay(value);
  };

  const createPayment = async () => {
    setPaymentLoading(true);

    try {
      let requestData = {
        id: selectedInvoice,
        debit_gl_code: selectedDebitAccount,
        // credit: selectedCreditAccount,
        amount: amountToPay || "",
      };

      // console.log(selectedInvoice, requestData);
      const response = await axios.post(
        `${BASE_URL}/pay-sales-invoice`,
        requestData,
        { headers }
      );

      toast.success(response.data.message);
      fetchData();
      fetchData7();
      fetchArchivedData();

      handleClose();

      setSelectedInvoice("");
      setAmountToPay("");
      setSelectedDebitAccount("");
      setDescription("");
      setAmount("");
      setTotalAmount("");
      setOutstanding("");
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        // console.log(errorMessage);
      }
    } finally {
      setPaymentLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(
      date.getMinutes()
    )} ${date.getHours() >= 12 ? "PM" : "AM"}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const options = opt.map((item) => ({
    value: item.id,
    label: item.name,
    data: item,
  }));

  const handleSelectChange = (selectedOption) => {
    const selectedItem = selectedOption.data;
    setProducts((prevProducts) => {
      // Check if item is already in the list
      const isAlreadyInList = prevProducts.some(
        (product) => product.id === selectedItem.id
      );
      if (isAlreadyInList) return prevProducts; // Prevent duplicates
      return [...prevProducts, { ...selectedItem, selectedQuantity: 1 }];
    });
  };

  const handleQuantityChange = (id, increment) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? {
              ...product,
              selectedQuantity: Math.min(
                product.selectedQuantity + increment,
                product.quantity // Limit to available stock
              ),
            }
          : product
      )
    );
  };
  const handleRemoveProduct = (id) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== id)
    );
  };

  const calculateTotalAmount = () => {
    return products.reduce(
      (total, product) =>
        total + product.selectedQuantity * parseFloat(product.price),
      0
    );
  };

  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];

  useEffect(() => {
    if (bearer) {
      // fetchData();
      fetchData1();
    }
  }, [bearer]);

  const handleClearItems = () => {
    setProducts([]);
  };

  // const calculateVAT = (total) => total * 0.075;

  const handlePrintReceipt = () => {
    navigate("/print_receipts", { state: { products } });
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      {/* <LatestDash /> */}

      <div>
        <OrderNav />

        {/* Tab Content */}
        <div className="tab-content">
          <div className={classes.finishedbodyCont}>
            <div className={classes.formSection}>
              <div className={classes.formSectionHeader}>


                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
