import React, { useState, useEffect } from 'react';
import classes from './Dashboard.module.css';
import avart from '../../assets/welc/sys.png';
// import avart from '../../assets/welc/avat2.png';
import first from '../../assets/welc/first.png';
import second from '../../assets/welc/second.png';
import third from '../../assets/welc/third.png';
import fifth from '../../assets/welc/fifth.png';
import firsts from '../../assets/welc/first1.png';
import seconds from '../../assets/welc/second2.png';
import thirds from '../../assets/welc/third3.png';
import fourths from '../../assets/welc/fourth4.png';
import fifths from '../../assets/welc/fifth5.png';
import firstss from '../../assets/welc/first11.png';
import secondss from '../../assets/welc/second22.png';
import thirdss from '../../assets/welc/third33.png';
import fourthss from '../../assets/welc/fourth44.png'
import TopNavBar from './TopNavBar';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';


const NewWelcomeScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const [admin, setAdmin] = useState("");
  const [user, setUser] = useState('');
  const [company, setCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [validity, setValidity] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [permId, setPermId] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [permits, setPermits] = useState([]);
  const [error, setError] = useState(null);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      // const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const value7 = await AsyncStorage.getItem('companyName');
      const value8 = await AsyncStorage.getItem('validity');
      const permitsss = await AsyncStorage.getItem('modules');

      if (value !== null && value.trim() !== "") {
        setBearer(value);
      } else {
        navigate('/login');
        return;
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map(permit => permit.name);
        setPermits(permitNames);
        // console.log(permitNames);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value8 !== null) {
        setValidity(value8);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (value7 !== null) {
        setCompany(value7);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const handleModuleClick = (module) => {
    if (permits.includes(module)) {
      // console.log(permits)
      setError(null);
      if (module === 'accounting') {
        navigate('/accounting');
      }
      if (module === 'receipt') {
        navigate('/cash_management');
      }
      if (module === 'payroll') {
        navigate('/payroll');
      }
      // if (module === 'Administration') {
      //     navigate('/admin');
      // }
      if (module === 'expenses') {
        navigate('/payment');
      }
      if (module === 'receivables') {
        navigate('/receivables');
      }
      if (module === 'payables') {
        navigate('/payables');
      }
      if (module === 'point_of_sales') {
        navigate('/sales_ord');
      }
      if (module === 'cooperative_management_system') {
        navigate('/cooperative');
      }
      if (module === 'event_centers_mgt_system') {
        navigate('/booking/dashboard');
      }
      if (module === 'inventory') {
        navigate('/inventory/dashboard');
      }
      if (module === 'fixed_asset') {
        navigate('/fixed_assets');
      }
      
      // if (module === 'inventory') {
      //   navigate('/inventory');
      // }
      // if (module === 'Users') {
      //     navigate('/admin');
      // }
      //   if (module === 'Fixed Assets') {
      //     window.open('https://fixed-asset.patna.ng/', '_blank');
      //   }
      //   if (module === 'Budgets') {
      //     window.open('https://pentsuite.com.ng/budget', '_blank');
      //   }
      //   if (module === 'Reconciliation') {
      //     window.open('https://pentsuite.com.ng/', '_blank');
      //   }
    } else {
      setError(`Access to ${module} is forbidden`);
      toast.error(`Access to ${module} is forbidden`);
    }
  };

  const handleLogout = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      navigate('/login');
      //   toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response) {
        if (error.response.status === 401) {
          navigate('/login');
        } else if (error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
      }
      //   toast.error(errorMessage);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <TopNavBar />
      <div className={classes.top}>
        <img src={avart} alt="Avatar" />
        <div className={classes.text}>
          <h6>Welcome</h6>
          <h3>{user.toLocaleUpperCase()}</h3>
          <p>Simplify your accounting operations</p>
        </div>
      </div>
      <div className={classes.general}>
        <div className={classes.tabs}>
          {/* <Link to='/employee'> */}
          <div className={classes.first} onClick={() => handleModuleClick('accounting')}>
            <img src={firstss} alt="First Tab" />
            <div className={classes.tabtext}>
              <p className={classes.fontChange}>Accounting</p>
              <p>Account Receivables , Account Payables , Banking and Cash Management, Accounting reports</p>
            </div>
          </div>
          {/* </Link> */}
          {/* <Link to='/designation'> */}
          <div className={classes.second} onClick={() => handleModuleClick('inventory')}>
            <img src={first} alt="First Tab" />
            <div className={classes.tabtext}>
              <p className={classes.fontChange}>Inventory</p>
              <p>Stock Management, Sales & Purchase Invoices, e.t.c.</p>
            </div>
          </div>
          {/* </Link> */}
          {/* <Link to='/'> */}
          <div className={classes.third}>
            <img src={second} alt="First Tab" />
            <div className={classes.tabtext}>
              <p className={classes.fontChange}>Reconciliation</p>
              <p>Reconcile Accounts, Generate Reconcilation Summary Report, e.t.c.</p>
            </div>
          </div>
          
          {/* </Link> */}
          {/* <Link to='/'>
                <div className={classes.fourth}>
                    <img src={third} alt="First Tab" />
                    <div className={classes.tabtext}>
                        
                    </div>
                </div>
                </Link> */}
          {/* <Link to='/dashboard'>
                <div className={classes.fifth}>
                    <img src={fifths} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Logout</p>
                        <p>Exit your portal</p>
                    </div>
                </div>
                </Link> */}
        </div>

        <div className={classes.tabs2}>
          {/* <Link to='/leave_management'> */}
          <div className={classes.first} onClick={() => handleModuleClick('cooperative_management_system')}>
            <img src={first} alt="First Tab" />
            <div className={classes.tabtext}>
              <p className={classes.fontChange}>Cooperative Management System</p>
              <p>Members Record, Savings & Loans, Monthly Deductions, e.t.c.</p>
            </div>
          </div>
          {/* </Link> */}
          {/* <Link to='/payroll'> */}
          <div className={classes.second} onClick={() => handleModuleClick('payroll')}>
            <img src={seconds} alt="First Tab" />
            <div className={classes.tabtext}>
              <p className={classes.fontChange}>Payroll Management</p>
              <p>Manage your employee leave requests</p>
            </div>
          </div>
          <div className={classes.second} onClick={() => handleModuleClick('point_of_sales')}>
            <img src={first} alt="First Tab" />
            <div className={classes.tabtext}>
              <p className={classes.fontChange}>Point of Sales</p>
              <p>Fixed Assets Register, Asset Disposal, Depreciations, e.t.c.</p>
            </div>
          </div>
          {/* </Link> */}
          {/* <Link to='/'> */}
          {/* <div className={classes.third} onClick={handleLogout}>
            {loading ? (
              <Spinner size="lg" animation="border" variant="success" />
            ) : (
              <>
                <img src={fifths} alt="Logout Tab" />
                <div className={classes.tabtext}>
                  <p>Logout</p>
                  <p>Exit your portal</p>
                </div>
              </>
            )}
          </div> */}

          {/* </Link> */}
          {/* <Link to='/'>
                <div className={classes.fourth}>
                    <img src={fourths} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Holiday/Benefits Management</p>
                        <p>Manage employees going on holidays and other benefits</p>
                    </div>
                </div>
                </Link> */}


        </div>
        <div className={classes.tabs3}>
          {/* <Link to='/add_dept'> */}
          <div className={classes.first} onClick={() => handleModuleClick('event_centers_mgt_system')}>
            <img src={fourthss} alt="First Tab" />
            <div className={classes.tabtext}>
              <p className={classes.fontChange}>Booking and Rentals</p>
              <p>Event Bookings, Rentals, Advance Payments, e.t.c.</p>
            </div>
          </div>
          {/* </Link> */}
          {/* <Link to='/job_application'> */}
          <div className={classes.second} onClick={() => handleModuleClick('fixed_asset')}>
            <img src={secondss} alt="First Tab" />
            <div className={classes.tabtext}>
              <p className={classes.fontChange}>Fixed Asset Management</p>
              <p>Fixed Assets Register, Asset Disposal, Depreciations, e.t.c.</p>
            </div>
          </div>
          {/* </Link> */}

          {/* <Link to='/'>
                <div className={classes.third}>
                    <img src={thirdss} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Exit Management</p>
                        <p>Manage exit of employees from your company</p>
                    </div>
                </div>
                </Link> */}
          {/* <Link to='/'>
                <div className={classes.fourth}>
                    <img src={fourthss} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Shifts and Schedules</p>
                        <p>Prepare and assign shifts to employees in your company</p>
                    </div>
                </div>
                </Link> */}

        </div>
      </div>

    </div>
  );
}

export default NewWelcomeScreen;
